import React from "react"
import MainLayout from "../components/MainLayout"
import PageHeading from "../components/PageHeadings"

import { Helmet } from "react-helmet"
import SEO from "../components/seo"

import ProductPageComponent from "../components/ProductPageComponent"

import Orbitz20Image from "../images/orbitz-20-full-view-w500.jpg"
import styled from "styled-components"

import DiskPadAssistImage from "../images/DiskPadAssist.jpg"
import DiskTractionImage from "../images/DiskTraction.jpg"
import OrbitalImage from "../images/orbital-deck-400.png"
import OrbitalImageThumb from "../images/orbital-deck-400.jpg"
import DiskPadImageLarge from "../images/DeckPadAssistLarge.jpg"
import DiskTractionImageLarge from "../images/DiskTractionLarge.jpg"

const K10PageStyle = styled.main`
  padding: 2rem;
`

const Orbitz20Page = () => {
  return (
    <>
      <SEO title="Floor Scrubbers: K10 Standard Walk-Behind Industrial Cleaning Equipment - Kodiak Cleaning Equipment" />
      <Helmet>
        <title>20" Orbital Floor Machine - Kodiak Cleaning Equipment</title>
        <description></description>
      </Helmet>
      <MainLayout>
        <PageHeading
          pageHeading='20" Orbital Floor Scrubber'

          // description="Here is the info about the K3 scrubbers and what they are used for"
        />
        <K10PageStyle>
          <ProductPageComponent
            mainDiskImage={Orbitz20Image}
            diskTitle='20" Orbital'
            subTitle="AC Powered"
            diskDescription='The ORBITZ 20" Floor Machine enables the operator to easily clean a variety of floor surfaces.  Learn how you can strip your floors chemical-free with orbital technology!  Pads are installed and removed without tools for easy application switching.  Heavy-duty rolling wheels allow for ease of transport.  Heavy-duty isolators reduce vibrations at the handle-bar reducing oeprator fatique and increasing ergonomics.  Containment Skirt standard to prevent solution or dust spread while in operation.'
            diskCleaningPath='20" Cleaning Width'
            descriptionTitle1="Weight:"
            diskGallons="144 - 232 lbs"
            cylindricalTitle="K11 Cylindrical"
            cylindricalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K11 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            cylindricalCleaningPath="17 - 20in (43 - 51cm)"
            cylindricalGallons="Sol: 11gal (42l) Rec: 12gal (45l)"
            cylindricalRunTime=" Up to 2.5 hours"
            orbitalTitle="K11 Orbital"
            orbitalDescription="Cleaning small areas with a budget in mind has just gotten easier with the K10 Series of Floor Scrubbers.  Don’t let the size fool you, this floor scrubber provides premium performance and durability compared to others in the market.  Maintain cleanliness in Auto Shops, Sports Arenas, and Small Warehouses easily.  The squeegee blades and brushes can be rotated or flipped without tools to allow maximum usage prior to replacement and to aid in easy changes."
            orbitalCleaningPath="17 - 20in (43 - 51cm)"
            orbitalGallons="Sol: 11gal (42l) Rec: 12gal (45l)"
            orbitalRunTime=" Up to 2.5 hours"
            chooseTitle="Documentation"
            // DeckImageThumb1={DiskPadAssistImage}
            // DeckImageThumb2={DiskTractionImage}
            // DeckImageThumb3={OrbitalImageThumb}
            // DeckImage1Description="Disk Pad Assist"
            // DeckImage2Description="Disk Traction"
            // DeckImage3Description="Orbital"
            // DeckImageLarge1={DiskPadImageLarge}
            // DeckImageLarge2={DiskTractionImageLarge}
            // DeckImageLarge3={OrbitalImage}
            deckTitle="Why Disk-Pad Assist?"
            // deckDescription1="Lowest Maintenance Cost"
            // deckDescription2="Brushes Individually Driven for Unmatched Performance"
            // deckDescription3="Improved Cleaning of Irregular Floors"
            deckTitle2="Why Disk-Traction?"
            deckDescription4="Powered Reverse"
            deckDescription5="Ramp Climbing"
            deckDescription6="Operator Ease"
            // deckTitle="Why Orbital"
            deckDescription7="Chemical Free Stripping"
            deckDescription8="70% Reduction in Water Usage"
            deckDescription9="Floor Finish Prep & Recoat"
            brochurePdf="../ORBITZ-SALES-SHEET.pdf"
            techPdf="../Orbitz-Kodiak-Specs.pdf"
            partsPdf="../KD-COMING-SOON.pdf"
            operatorPdf="../KD-COMING-SOON.pdf"
            displayHood="none"
            showPM="none"
            mtLink="https://www.dropbox.com/s/evrcuco63ko0478/daily-weekly-maintenance-3rd-v21306.pdf?dl=0"
            ></ProductPageComponent>
        </K10PageStyle>
      </MainLayout>
    </>
  )
}

export default Orbitz20Page
